import { PageProps } from 'gatsby'
import React, { FC, useMemo } from 'react'

import { PageTemplateQuery } from '../graphql/gatsby'
import Head from '../layouts/Head'
import type { SanityPageContext as PageContext } from '../lib/pages'
import { SanityPageContext } from '../lib/sanity/context'
import Layout from './global/Layout'
import SanityPageComponents from './sanity-page/SanityPageComponents'

export type SanityPageProps = Pick<
  PageProps<PageTemplateQuery, PageContext>,
  'data' | 'pageContext'
>

export const SanityPage: FC<SanityPageProps> = ({ data, pageContext }) => {
  const sanityPageContextValue = useMemo(() => {
    const { page, ...contextValue } = data
    return contextValue
  }, [data])
  const page = data.page
  const content = page?._rawContent

  return (
    <SanityPageContext.Provider value={sanityPageContextValue}>
      <Head
        title={page?.title ?? undefined}
        description={page?.description ?? undefined}
        keywords={page?.keywords}
        image={page?._rawOpenGraphImage}
      />
      <Layout showHeader={pageContext.slug !== 'register'} fullWidth={page?.maxWidth === '100%'}>
        {content &&
          content.map((item: { _type: string; _key: string; [key: string]: unknown }) =>
            React.createElement(SanityPageComponents(item._type), {
              key: item._key,
              ...item,
            }),
          )}
      </Layout>
    </SanityPageContext.Provider>
  )
}
